import React from "react"

import MainSection from "../../objects/MainSection"
import { Container, Title, Obs, BkgImage } from "./styles"

const FreeShippingSection = ({ imgFluid }) => {
   return (
      <MainSection>
         <Container>
            <Title>Frete grátis para todas as capitais brasileiras **</Title>
            <Obs>** consulte as condições</Obs>
         </Container>
         <BkgImage fluid={imgFluid} />
      </MainSection>
   )
}

export default FreeShippingSection