import styled from "styled-components";
import { Link } from "gatsby"
import Img from "gatsby-image"
import media from "../../styles/breakPoints"

const WrapCard = styled.div`
   position: relative;
   width: 100%;
   height: 100%;
   border: none;
   outline: none;
   text-decoration: none;
   box-sizing: border-box;
   cursor: default;
   pointer-events: auto;

   ${media.greaterThan("lg")`
      transition: all 300ms linear;
      &:hover {
         transform: scale(1.01);
         transition: all 300ms linear;
         box-shadow: ${props => props.theme.shadow.primary};
      }
   `}

`

const LinkCard = styled(Link)`
   position: absolute;
   display: block;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 5;
   &.-notpointer {
      cursor: default;
      pointer-events: none;
   }
`

const Label = styled.span`
   display: inline-block;
   text-indent: -9999px;
`

const CardItem = styled.div`
   position: relative;
   box-sizing: border-box;
`

const CardImage = styled(Img)`
   position: absolute;
   top: 0;
   width: 100%;
   padding: 10px 10px 0;
`

const Content = styled.div`
   padding: 20px 30px 30px;
`

const Title = styled.h3`
  font-family: ${props => props.theme.font.primary};
  font-size: ${props => props.theme.size.mediumSmall};
  font-weight: 600;
  line-height: 2.4rem;
  color: ${props => props.theme.color.fourthDark2};
  text-transform: uppercase;
  margin: 0;
`

const Description = styled.p`
  font-family: ${props => props.theme.font.secondy};
  font-size: ${props => props.theme.size.small};
  font-weight: 400;
  color: ${props => props.theme.color.fourthDark2};
  margin: 0;
`

export {
   WrapCard,
   LinkCard,
   Label,
   CardItem,
   CardImage,
   Content,
   Title,
   Description,
}