import React from "react";

import { WrapSection } from "./styles"

const CardsSection = ({ className, children }) => {

   return (
      <WrapSection id="novidades" className={className}>
         {children}
      </WrapSection>
   )
}

export default CardsSection