import React from "react";

import { WrapCard, 
         LinkCard,
         Label,
         CardItem, 
         CardImage,
         Content, 
         Title, 
         Description } from "./styles"

const CardProduct = ({  imgFluid, 
                        alt, 
                        title, 
                        description, 
                        className, 
                        target }) => {

   return (
      <WrapCard className={className}>
         <LinkCard   to={!target ? "/" : target} 
                     className={!target ? "-notpointer" : ""}>
            <Label>{title}</Label>
         </LinkCard>
         <CardItem className={className}>
            <CardImage fluid={imgFluid} alt={alt} />
            <Content>
               <Title>{title}</Title>
               <Description>{description}</Description>
            </Content>
         </CardItem>
      </WrapCard>
   )
}

export default CardProduct