import styled from "styled-components"
import media from "../../styles/breakPoints"

const WrapSection = styled.section`
   width: 100%;
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: repeat(3, 1fr);
   grid-row-gap: 20px;

   ${media.greaterThan("md")`
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;   
   `}

   ${media.greaterThan("lg")`
      border-left: 22.4rem solid transparent;
   `}

   ${media.greaterThan("xl")`
      & > * {
         background-color: ${props => props.theme.color.secondyLight1};
      }
   `}

`
export {
   WrapSection
}